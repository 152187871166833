import { ConduxService } from "@/services/condux.service";
import {
  ConduxApiCommonV1Response,
  ConduxApiResearcherV1FaceSwapPostRequest,
  ConduxApiResearcherV1FaceSwapStudyGetResponse,
  ConduxApiResearcherV1FaceSwapStudiesGetResponse,
  ConduxApiResearcherV1FaceSwapJobPostRequest,
  ConduxApiResearcherV1FaceSwapJobGetReponse,
  ConduxApiResearcherV1FaceSwapJobsGetReponse,
  FaceReplacementStudy,
  StudyV2Helper,
} from "@conduxio/types";

export class StudyFaceSwapService extends ConduxService {
  protected modelNameSingular = "study-v2";
  protected modelNamePlural = "studies-v2";

  public read(id: string, withoutWorkspaceId = false, workspaceId = "", include = ""): Promise<ConduxApiResearcherV1FaceSwapStudyGetResponse> {
    const apiRequest = {
      url: this.modelNameSingular + "/face-replacement/" + id,
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      include: include,
    };

    const ret = super.get<ConduxApiResearcherV1FaceSwapStudyGetResponse>(apiRequest);

    return ret;
  }

  public readAll(withoutWorkspaceId = false, workspaceId = "", include = ""): Promise<ConduxApiResearcherV1FaceSwapStudiesGetResponse> {
    const apiRequest = {
      url: this.modelNameSingular + "/face-replacement",
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      include: include,
    };

    const ret = super.get<ConduxApiResearcherV1FaceSwapStudiesGetResponse>(apiRequest);

    return ret;
  }

  public create(
    request: ConduxApiResearcherV1FaceSwapPostRequest,
    withoutWorkspaceId = false,
    workspaceId = ""
  ): Promise<ConduxApiResearcherV1FaceSwapStudyGetResponse> {
    const apiRequest = {
      url: this.modelNameSingular + "/face-replacement",
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      object: request,
    };

    return super.post<ConduxApiResearcherV1FaceSwapStudyGetResponse>(apiRequest);
  }

  public update(model: FaceReplacementStudy, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiResearcherV1FaceSwapStudyGetResponse> {
    const validationResult = StudyV2Helper.validate(model);

    if (!validationResult.valid) {
      return new Promise<ConduxApiResearcherV1FaceSwapStudyGetResponse>((resolve) => {
        resolve({
          status: "ERROR",
          errorMessage: "Error updating Study, " + validationResult.invalidReason,
        } as ConduxApiResearcherV1FaceSwapStudyGetResponse);
      });
    }

    const apiRequest = {
      url: this.modelNameSingular + "/face-replacement/" + model.id,
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      object: { study: validationResult.study },
      modelId: model.id,
    };

    return super.put<ConduxApiResearcherV1FaceSwapStudyGetResponse>(apiRequest);
  }

  public remove(id: string, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiCommonV1Response> {
    const apiRequest = {
      url: this.modelNameSingular + "/face-replacement/" + id,
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      modelId: id,
    };

    return super.delete<ConduxApiCommonV1Response>(apiRequest);
  }

  public readJob(id: string, withoutWorkspaceId = false, workspaceId = "", include = ""): Promise<ConduxApiResearcherV1FaceSwapJobGetReponse> {
    const apiRequest = {
      url: this.modelNameSingular + "/face-replacement-job/" + id,
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      include: include,
    };

    const ret = super.get<ConduxApiResearcherV1FaceSwapJobGetReponse>(apiRequest);

    return ret;
  }

  public readAllJobs(withoutWorkspaceId = false, workspaceId = "", include = ""): Promise<ConduxApiResearcherV1FaceSwapJobsGetReponse> {
    const apiRequest = {
      url: this.modelNameSingular + "/face-replacement-job",
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      include: include,
    };

    const ret = super.get<ConduxApiResearcherV1FaceSwapJobsGetReponse>(apiRequest);

    return ret;
  }

  public createJob(
    request: ConduxApiResearcherV1FaceSwapJobPostRequest,
    withoutWorkspaceId = false,
    workspaceId = ""
  ): Promise<ConduxApiResearcherV1FaceSwapJobsGetReponse> {
    const apiRequest = {
      url: this.modelNameSingular + "/face-replacement-job",
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      object: request,
    };

    return super.post<ConduxApiResearcherV1FaceSwapJobsGetReponse>(apiRequest);
  }

  public removeJob(id: string, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiCommonV1Response> {
    const apiRequest = {
      url: this.modelNameSingular + "/face-replacement-job/" + id,
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      modelId: id,
    };

    return super.delete<ConduxApiCommonV1Response>(apiRequest);
  }
}
