import { ConduxService } from "./condux.service";
import { SurveyJob, ConduxApiParticipantGetSurvey, ConduxApiParticipantGetJobs, ConduxApiParticipantGetJob } from "@conduxio/types";

export class ParticipantStudySurveyService extends ConduxService {
  protected modelNameSingular = "study-v2";
  protected modelNamePlural = "studies-v2";

  public read(studyId: string): Promise<ConduxApiParticipantGetSurvey> {
    const apiRequest = { url: this.modelNameSingular + "/survey-participant/" + studyId };

    return super.get(apiRequest);
  }

  public readJobs(studyId: string): Promise<ConduxApiParticipantGetJobs> {
    const apiRequest = { url: this.modelNameSingular + "/survey-job/" + studyId };

    return super.get(apiRequest);
  }

  public createJob(model: SurveyJob): Promise<ConduxApiParticipantGetJob> {
    const apiRequest = { url: this.modelNameSingular + "/survey-job/", object: model };

    return super.post<ConduxApiParticipantGetJob>(apiRequest);
  }

  public updateJob(model: SurveyJob): Promise<ConduxApiParticipantGetJob> {
    const apiRequest = { url: this.modelNameSingular + "/survey-job/" + model.id, modelId: model.id, object: model };

    return super.put<ConduxApiParticipantGetJob>(apiRequest);
  }
}
