import { ConduxService } from "@/services/condux.service";

import {
  ConduxApiCommonV1Response,
  ConduxApiResearcherV1ResearcherFederatedLoginPostBody,
  ConduxApiResearcherV1ResearcherGetResponse,
  ConduxApiResearcherV1ResearcherLoginPostBody,
  ConduxApiResearcherV1ResearcherLoginPostResponse,
  ConduxAPIResearcherV1ResearcherPasswordPutBody,
  ConduxApiResearcherV1ResearcherPasswordResetGetRequest,
  ConduxApiResearcherV1ResearcherPasswordResetPutBody,
  ConduxApiResearcherV1ResearcherPostResponse,
  ConduxApiResearcherV1ResearcherPutResponse,
  ConduxApiResearcherV1ResearchersGetRequest,
  ConduxApiResearcherV1ResearchersGetResponse,
  ConduxApiResearcherV1ResearcherProfilePutBody,
  ConduxApiResearcherV1ResearcherPasswordResetPostBody,
  Researcher,
  Workspace,
  Role,
  UsersHelper,
} from "@conduxio/types";

export class ResearcherService extends ConduxService {
  protected modelNameSingular = "researcher";
  protected modelNamePlural = "researchers";

  public async login(
    body: ConduxApiResearcherV1ResearcherLoginPostBody,
    workspaceId = undefined as string | undefined
  ): Promise<ConduxApiResearcherV1ResearcherLoginPostResponse> {
    const apiRequest = { url: this.modelNameSingular + "/login", withoutWorkspaceId: true, object: body, modelName: "login" };

    return super.post<ConduxApiResearcherV1ResearcherLoginPostResponse>(apiRequest).then(async (response) => {
      if (response.status === "OK") {
        if (!response.researcher.isPasswordResetRequired) {
          this.globalsService.loggedInUser = response.researcher as Researcher;
          this.globalsService.userIdToken = response.idToken as string;
          this.globalsService.userRefreshToken = response.refreshToken as string;
          await this.setGlobalServiceData(response.researcher, workspaceId);

          const serverTimestampDifference = Date.now() - (response.serverTimestamp as number);

          this.globalsService.serverTimestampDifference = serverTimestampDifference;
        }

        return response;
      } else {
        return {
          status: "ERROR",
          errorMessage: response.errorMessage,
        } as ConduxApiResearcherV1ResearcherLoginPostResponse;
      }
    });
  }

  public async federatedSignIn(
    body: ConduxApiResearcherV1ResearcherFederatedLoginPostBody,
    workspaceId = undefined as string | undefined
  ): Promise<ConduxApiResearcherV1ResearcherLoginPostResponse> {
    const apiRequest = { url: this.modelNameSingular + "/federated-login", withoutWorkspaceId: true, object: body, modelName: "federated login" };

    return super.post<ConduxApiResearcherV1ResearcherLoginPostResponse>(apiRequest).then(async (response) => {
      if (response.status === "OK") {
        if (!response.researcher.isPasswordResetRequired) {
          this.globalsService.loggedInUser = response.researcher as Researcher;
          this.globalsService.userIdToken = response.idToken as string;
          this.globalsService.userRefreshToken = response.refreshToken as string;
          await this.setGlobalServiceData(response.researcher, workspaceId);

          const serverTimestampDifference = Date.now() - (response.serverTimestamp as number);

          this.globalsService.serverTimestampDifference = serverTimestampDifference;
        }

        return response;
      } else {
        return {
          status: "ERROR",
          errorMessage: response.errorMessage,
        } as ConduxApiResearcherV1ResearcherLoginPostResponse;
      }
    });
  }

  public async changeLogin(researcher: Researcher): Promise<void> {
    super.checkTokenRefresh();
    await this.setGlobalServiceData(researcher);
  }

  private async setGlobalServiceData(researcher: Researcher, workspaceId = undefined as string | undefined): Promise<void> {
    // Auto select workspace role if there is only one role - majority of users
    this.globalsService.userWorkspaces = [];
    this.globalsService.workspaceRoles = [];

    // New API: the new login API provides also the researchers's user_assigned_roles with roles and workspaces inside the researcher
    // Therefore, we don't need to call other APIs to get them
    for (const assignedRole of researcher.userAssignedRoles) {
      // TODO: remove "eslint-disable" and ! when we have the right types
      // eslint-disable-next-line
      // @ts-ignore
      const workspace: Workspace = assignedRole.workspace!;
      const role: Role = assignedRole.role!;

      role.workspaceId = workspace.id;
      this.globalsService.userWorkspaces.push(workspace);
      this.globalsService.workspaceRoles.push(role);
    }

    this.globalsService.isWorkspaceSet = true;
    if (this.globalsService.userWorkspaces.length > 0) {
      // if i force a workspace from queryurl
      const indexWorkspace = this.globalsService.userToWorkspaceId.findIndex((sw) => sw.userId === this.globalsService.loggedInUser.id);

      if (workspaceId && this.globalsService.userWorkspaces.find((ws) => ws.id === workspaceId)) {
        this.globalsService.userWorkspace = this.globalsService.userWorkspaces.find((ws) => ws.id === workspaceId) as Workspace;
        //this part is for update the array of userToWorkspaceId
        if (indexWorkspace >= 0) {
          this.globalsService.userToWorkspaceId[indexWorkspace].workspaceId = workspaceId;
        } else {
          this.globalsService.userToWorkspaceId.push({
            userId: this.globalsService.loggedInUser.id as string,
            workspaceId: workspaceId,
          });
        }
      } else {
        // if i have a selected workspace in local storage
        if (indexWorkspace >= 0) {
          this.globalsService.userWorkspace = this.globalsService.userWorkspaces.find(
            (w) => w.id === this.globalsService.userToWorkspaceId[indexWorkspace].workspaceId
          ) as Workspace;

          //if the workspace in local storage is old/wrong
          if (!this.globalsService.userWorkspace) {
            this.globalsService.userWorkspace = this.globalsService.userWorkspaces[0];
            this.globalsService.userToWorkspaceId[indexWorkspace].workspaceId = this.globalsService.userWorkspace.id as string;
            this.globalsService.isWorkspaceSet = false;
          }
        } else {
          // if i dont have a selected workspace in local storage
          this.globalsService.userWorkspace = this.globalsService.userWorkspaces[0];
          this.globalsService.userToWorkspaceId.push({
            userId: this.globalsService.loggedInUser.id as string,
            workspaceId: this.globalsService.userWorkspace.id as string,
          });
          // if there are multiple workspace
          if (this.globalsService.userWorkspaces.length !== 1) {
            this.globalsService.isWorkspaceSet = false;
          }
        }
      }

      // Selects the role in the active workspace
      this.globalsService.userRole = this.globalsService.workspaceRoles.find((r) => r.workspaceId === this.globalsService.userWorkspace.id) as Role;
    } else {
      throw new Error("Workspace empty for this user");
    }
  }

  public create(researcher: Researcher, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiResearcherV1ResearcherPostResponse> {
    const validationResult = UsersHelper.validateResearcher(researcher);

    if (!validationResult.valid) {
      return new Promise<ConduxApiResearcherV1ResearcherPostResponse>((resolve) => {
        resolve({
          status: "ERROR",
          errorMessage: "Error creating User, " + validationResult.invalidReason,
        } as ConduxApiResearcherV1ResearcherPostResponse);
      });
    }

    const apiRequest = {
      url: this.modelNameSingular,
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      object: { researcher: validationResult.researcher },
    };

    return super.post<ConduxApiResearcherV1ResearcherPostResponse>(apiRequest);
  }

  public read(id: string, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiResearcherV1ResearcherGetResponse> {
    const apiRequest = { url: this.modelNameSingular + "/" + id, withoutWorkspaceId: withoutWorkspaceId, workspaceId: workspaceId };

    return super.get<ConduxApiResearcherV1ResearcherGetResponse>(apiRequest);
  }

  public readAll(
    request: ConduxApiResearcherV1ResearchersGetRequest,
    withoutWorkspaceId = false,
    workspaceId = ""
  ): Promise<ConduxApiResearcherV1ResearchersGetResponse> {
    const apiRequest = { url: this.modelNameSingular, withoutWorkspaceId: withoutWorkspaceId, workspaceId: workspaceId, request: request };

    return super.get<ConduxApiResearcherV1ResearchersGetResponse>(apiRequest);
  }

  /**
   * This function will check if the updated user is the logged-in user an update the globalsService variable accordingly
   *
   * @param researcher
   */
  public update(researcher: Researcher, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiResearcherV1ResearcherPutResponse> {
    const validationResult = UsersHelper.validateResearcher(researcher);

    if (!validationResult.valid) {
      return new Promise<ConduxApiResearcherV1ResearcherPutResponse>((resolve) => {
        resolve({
          status: "ERROR",
          errorMessage: "Error updating User, " + validationResult.invalidReason,
        } as ConduxApiResearcherV1ResearcherPutResponse);
      });
    }

    return new Promise<ConduxApiResearcherV1ResearcherPutResponse>(() => {
      const apiRequest = {
        url: this.modelNameSingular + "/" + researcher.id,
        withoutWorkspaceId: withoutWorkspaceId,
        workspaceId: workspaceId,
        object: { researcher: validationResult.researcher },
        modelId: researcher.id,
      };

      return super.put<ConduxApiResearcherV1ResearcherPutResponse>(apiRequest).then((response) => {
        if (response.status === "OK") {
          if (response.researcher.id === this.globalsService.loggedInUser.id) {
            this.globalsService.loggedInUser = response.researcher;
          }
        }
      });
    });
  }
  public remove(id: string, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiCommonV1Response> {
    const apiRequest = { url: this.modelNameSingular + "/" + id, withoutWorkspaceId: withoutWorkspaceId, workspaceId: workspaceId, modelId: id };

    return super.delete<ConduxApiCommonV1Response>(apiRequest);
  }
  //update profile in account page
  public profileUpdate(request: ConduxApiResearcherV1ResearcherProfilePutBody): Promise<ConduxApiResearcherV1ResearcherPutResponse> {
    const apiRequest = { url: this.modelNameSingular + "/profile", object: request, modelId: "fake" };

    return super.put<ConduxApiResearcherV1ResearcherPutResponse>(apiRequest);
  }
  //update password in account page
  public passwordUpdate(body: ConduxAPIResearcherV1ResearcherPasswordPutBody): Promise<ConduxApiCommonV1Response> {
    const apiRequest = { url: this.modelNameSingular + "/password", object: body, modelId: "fake", modelName: "password" };

    return super.put<ConduxApiCommonV1Response>(apiRequest);
  }
  //reset the password when not logged in PasswordForgot page
  public passwordResetGet(request: ConduxApiResearcherV1ResearcherPasswordResetGetRequest): Promise<ConduxApiCommonV1Response> {
    const apiRequest = { url: this.modelNameSingular + "/password-reset", request: request, modelName: "password", withoutWorkspaceId: true };

    return super.get<ConduxApiCommonV1Response>(apiRequest);
  }
  //set the new password when not logged in PasswordResetpage
  public passwordResetPut(body: ConduxApiResearcherV1ResearcherPasswordResetPutBody): Promise<ConduxApiCommonV1Response> {
    const apiRequest = {
      url: this.modelNameSingular + "/password-reset",
      object: body,
      modelId: "fake",
      modelName: "password reset",
      withoutWorkspaceId: true,
    };

    return super.put<ConduxApiCommonV1Response>(apiRequest);
  }
  //reset the password of other user when logged in AccessUser Page
  public passwordResetPost(body: ConduxApiResearcherV1ResearcherPasswordResetPostBody): Promise<ConduxApiCommonV1Response> {
    const apiRequest = { url: this.modelNameSingular + "/password-reset", object: body, modelName: "password reset" };

    return super.post<ConduxApiCommonV1Response>(apiRequest);
  }
}

let instance: ResearcherService;

function setResearcherServiceInstance(researcherServiceInstance: ResearcherService): void {
  instance = researcherServiceInstance;
}

function getResearcherServiceInstance(): ResearcherService {
  /*
    Date    2023-06-15
    Author  Simone
    Task    "Retore and Improve refreshToken functionallity" https://app.clickup.com/t/863gzuva1
    Desc    in the previous code, this function was called by the router/index.ts,
            and this was probabily meant to refer the ResearcherService Globally.
            After 2023-05-13 this cannot work, and we're not able to identify the reason.
            What we know is the getResearcherServiceInstance returns an "undefined" object.
            Of corse, there's more code we've lost somewhere else. The problem is "where???"
            Currently, this function isn't used anymore - from any where in the frontend
  **/
  return instance;
}

export { getResearcherServiceInstance, setResearcherServiceInstance };
