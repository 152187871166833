import * as Sentry from "@sentry/vue";
import router from "../router";

// Types
import type { App } from "vue";

export function registerSentry(app: App): void {
  Sentry.init({
    app,
    enabled: import.meta.env.VITE_APP_DEVELOPMENT !== "true",
    integrations: [
      // Or omit `router` if you're not using vue-router
      Sentry.browserTracingIntegration({ router }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // eslint-disable-next-line no-unused-vars
    beforeSend(event, _hint) {
      return event;
    },
  });
}
