import { ConduxService } from "./condux.service";
import { Participant, ConduxApiParticipantGetParticipantUser } from "@conduxio/types";

export class ParticipantUserService extends ConduxService {
  protected modelNameSingular = "participant-user";
  protected modelNamePlural = "participant-users";

  public create(model: Participant): Promise<ConduxApiParticipantGetParticipantUser> {
    const apiRequest = { url: this.modelNameSingular, object: model };

    return super.post<ConduxApiParticipantGetParticipantUser>(apiRequest);
  }
}
