import icons from "@/static/icons";
export class fileHelper {
  //image
  static imageExtensions = [".png", ".jpg", ".jpeg", ".bmp", ".webp"] as string[];
  //document
  static wordExtensions = [".doc", ".docx", ".docm", ".odt", ".odp", ".ods"] as string[];
  static excelExtensions = [".xls", ".xlsx", ".xlsm"] as string[];
  static powerPointExtensions = [".pptx"] as string[];
  static pdfExtensions = [".pdf"] as string[];
  //video
  static videoExtensions = [".mp4", ".mov", ".avi", ".wmv", ".mpg", ".mpeg"] as string[];

  static getSupportedExtensions(fileType: "document" | "image" | "video"): string {
    if (fileType === "image") {
      return String(fileHelper.imageExtensions);
    } else if (fileType === "video") {
      return String(fileHelper.videoExtensions);
    } else if (fileType === "document") {
      return (
        String(fileHelper.wordExtensions) +
        "," +
        String(fileHelper.excelExtensions) +
        "," +
        String(fileHelper.powerPointExtensions) +
        "," +
        String(fileHelper.pdfExtensions)
      );
    }
    return "";
  }

  static getExtension(fileName: string): string {
    const extension = fileName ? fileName.substring(fileName.lastIndexOf(".")) : "noext";

    return extension;
  }
  static getIconByExtension(filename: string): string {
    const extension = this.getExtension(filename);
    if (extension) {
      if (this.wordExtensions.includes(extension)) return new URL("@/assets/images/files_icons/doc.png", import.meta.url).href;
      else if (this.excelExtensions.includes(extension)) return new URL("@/assets/images/files_icons/xls.png", import.meta.url).href;
      else if (this.powerPointExtensions.includes(extension)) return new URL("@/assets/images/files_icons/pptx.png", import.meta.url).href;
      else if (this.pdfExtensions.includes(extension)) return new URL("@/assets/images/files_icons/pdf.png", import.meta.url).href;
      else if (this.videoExtensions.includes(extension)) return icons.video;
    }

    return "";
  }
}
