const modules = import.meta.glob("@/components/base/*.vue", { eager: true });

export default function registerComponents(app): void {
  Object.entries(modules).forEach(([item, definition]: [string, any]) => {
    const componentName = item
      ?.split("/")
      ?.pop()
      ?.replace(/\.\w+$/, "");
    app.component(componentName, definition.default);
  });
}
