import { ConduxService } from "@/services/condux.service";
import {
  ConduxApiCommonV1Response,
  ConduxApiResearcherV1ScheduleGetResponse,
  ConduxApiResearcherV1SchedulePostResponse,
  ConduxApiResearcherV1SchedulePutResponse,
  ConduxApiResearcherV1SchedulesGetRequest,
  ConduxApiResearcherV1SchedulesGetResponse,
  Schedule,
  ScheduleHelper,
} from "@conduxio/types";

export class ScheduleService extends ConduxService {
  protected modelNameSingular = "schedule";
  protected modelNamePlural = "schedules";

  // New API: schedules don't require workspace_id: this doesn't make sense, since each researcher can have just one schedule
  public read(id: string, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiResearcherV1ScheduleGetResponse> {
    withoutWorkspaceId = true;
    workspaceId = "";

    const apiRequest = { url: this.modelNameSingular + "/" + id, withoutWorkspaceId: withoutWorkspaceId, workspaceId: workspaceId };
    return super.get<ConduxApiResearcherV1ScheduleGetResponse>(apiRequest);
  }

  // New API: schedules don't require workspace_id: this doesn't make sense, since each researcher can have just one schedule
  public readAll(
    request: ConduxApiResearcherV1SchedulesGetRequest,
    withoutWorkspaceId = false,
    workspaceId = ""
  ): Promise<ConduxApiResearcherV1SchedulesGetResponse> {
    withoutWorkspaceId = true;
    workspaceId = "";

    const apiRequest = { url: this.modelNameSingular, withoutWorkspaceId: withoutWorkspaceId, workspaceId: workspaceId, request: request };
    return super.get<ConduxApiResearcherV1SchedulesGetResponse>(apiRequest);
  }

  // New API: schedules don't require workspace_id: this doesn't make sense, since each researcher can have just one schedule
  public create(model: Schedule, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiResearcherV1SchedulePostResponse> {
    withoutWorkspaceId = true;
    workspaceId = "";

    const validationResult = ScheduleHelper.validate(model);
    if (!validationResult.valid) {
      return new Promise<ConduxApiResearcherV1SchedulePostResponse>((resolve) => {
        resolve({
          status: "ERROR",
          errorMessage: "Error creating Schedule, " + validationResult.invalidReason,
        } as ConduxApiResearcherV1SchedulePostResponse);
      });
    }

    const apiRequest = {
      url: this.modelNameSingular,
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      object: { schedule: validationResult.schedule },
    };
    return super.post<ConduxApiResearcherV1SchedulePostResponse>(apiRequest);
  }

  // New API: schedules don't require workspace_id: this doesn't make sense, since each researcher can have just one schedule
  public update(model: Schedule, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiResearcherV1SchedulePutResponse> {
    withoutWorkspaceId = true;
    workspaceId = "";

    const validationResult = ScheduleHelper.validate(model);
    if (!validationResult.valid) {
      return new Promise<ConduxApiResearcherV1SchedulePutResponse>((resolve) => {
        resolve({
          status: "ERROR",
          errorMessage: "Error updating Schedule, " + validationResult.invalidReason,
        } as ConduxApiResearcherV1SchedulePutResponse);
      });
    }

    const apiRequest = {
      url: this.modelNameSingular + "/" + model.id,
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      object: { schedule: validationResult.schedule },
      modelId: model.id,
    };
    return super.put<ConduxApiResearcherV1SchedulePutResponse>(apiRequest);
  }

  // New API: schedules don't require workspace_id: this doesn't make sense, since each researcher can have just one schedule
  public remove(id: string, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiCommonV1Response> {
    withoutWorkspaceId = true;
    workspaceId = "";

    const apiRequest = { url: this.modelNameSingular + "/" + id, withoutWorkspaceId: withoutWorkspaceId, workspaceId: workspaceId, modelId: id };
    return super.delete<ConduxApiCommonV1Response>(apiRequest);
  }
}
