import { ConduxService } from "@/services/condux.service";
import {
  ConduxApiCommonV1Response,
  ConduxApiResearcherV1SessionTagPostResponse,
  ConduxApiResearcherV1SessionTagPutResponse,
  ConduxApiResearcherV1SessionTagsGetResponse,
  Session,
  SessionTag,
  SessionHelper,
} from "@conduxio/types";

export class SessionTagService extends ConduxService {
  protected modelNameSingular = "session-tag";
  protected modelNamePlural = "session-tags";

  public readAll(sessionId: string, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiResearcherV1SessionTagsGetResponse> {
    const apiRequest = {
      url: this.modelNameSingular + "/" + sessionId,
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      request: {},
    };

    return super.get<ConduxApiResearcherV1SessionTagsGetResponse>(apiRequest);
  }

  public create(model: SessionTag, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiResearcherV1SessionTagPostResponse> {
    const validationResult = SessionHelper.validateTag(model, false);

    if (!validationResult.valid) {
      return new Promise<ConduxApiResearcherV1SessionTagPostResponse>((resolve) => {
        resolve({
          status: "ERROR",
          errorMessage: "Error creating SessionTag, " + validationResult.invalidReason,
        } as ConduxApiResearcherV1SessionTagPostResponse);
      });
    }

    const apiRequest = {
      url: this.modelNameSingular,
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      object: { sessionTag: validationResult.sessionTag },
    };

    return super.post<ConduxApiResearcherV1SessionTagPostResponse>(apiRequest);
  }

  public update(model: Session, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiResearcherV1SessionTagPutResponse> {
    const validationResult = SessionHelper.validateTag(model);

    if (!validationResult.valid) {
      return new Promise<ConduxApiResearcherV1SessionTagPutResponse>((resolve) => {
        resolve({
          status: "ERROR",
          errorMessage: "Error updating SessionTag, " + validationResult.invalidReason,
        } as ConduxApiResearcherV1SessionTagPutResponse);
      });
    }

    const apiRequest = {
      url: this.modelNameSingular + "/" + model.id,
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      object: { sessionTag: validationResult.sessionTag },
      modelId: model.id,
    };

    return super.put<ConduxApiResearcherV1SessionTagPutResponse>(apiRequest);
  }

  public remove(id: string, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiCommonV1Response> {
    const apiRequest = { url: this.modelNameSingular + "/" + id, withoutWorkspaceId: withoutWorkspaceId, workspaceId: workspaceId, modelId: id };

    return super.delete<ConduxApiCommonV1Response>(apiRequest);
  }
}
