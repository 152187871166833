import { ConduxService } from "@/services/condux.service";
import {
  ConduxApiCommonV1Response,
  ConduxApiResearcherV1SessionCommentPostResponse,
  ConduxApiResearcherV1SessionCommentPutResponse,
  ConduxApiResearcherV1SessionCommentsGetResponse,
  SessionComment,
  SessionHelper,
} from "@conduxio/types";

export class SessionCommentService extends ConduxService {
  protected modelNameSingular = "session-comment";
  protected modelNamePlural = "session-comments";

  public readAll(sessionId: string, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiResearcherV1SessionCommentsGetResponse> {
    const apiRequest = {
      url: this.modelNameSingular + "/" + sessionId,
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      request: {},
    };

    return super.get<ConduxApiResearcherV1SessionCommentsGetResponse>(apiRequest);
  }

  public create(model: SessionComment, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiResearcherV1SessionCommentPostResponse> {
    const validationResult = SessionHelper.validateComment(model);

    if (!validationResult.valid) {
      return new Promise<ConduxApiResearcherV1SessionCommentPostResponse>((resolve) => {
        resolve({
          status: "ERROR",
          errorMessage: "Error creating SessionComment, " + validationResult.invalidReason,
        } as ConduxApiResearcherV1SessionCommentPostResponse);
      });
    }

    const apiRequest = {
      url: this.modelNameSingular,
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      object: { sessionComment: validationResult.sessionComment },
    };

    return super.post<ConduxApiResearcherV1SessionCommentPostResponse>(apiRequest);
  }

  public update(model: SessionComment, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiResearcherV1SessionCommentPutResponse> {
    const validationResult = SessionHelper.validateComment(model);

    if (!validationResult.valid) {
      return new Promise<ConduxApiResearcherV1SessionCommentPutResponse>((resolve) => {
        resolve({
          status: "ERROR",
          errorMessage: "Error updating SessionComment, " + validationResult.invalidReason,
        } as ConduxApiResearcherV1SessionCommentPutResponse);
      });
    }

    const apiRequest = {
      url: this.modelNameSingular + "/" + model.id,
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      object: { sessionComment: validationResult.sessionComment },
      modelId: model.id,
    };

    return super.put<ConduxApiResearcherV1SessionCommentPutResponse>(apiRequest);
  }

  public remove(id: string, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiCommonV1Response> {
    const apiRequest = { url: this.modelNameSingular + "/" + id, withoutWorkspaceId: withoutWorkspaceId, workspaceId: workspaceId, modelId: id };

    return super.delete<ConduxApiCommonV1Response>(apiRequest);
  }
}
