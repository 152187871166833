/* eslint-disable */
// interface Object {
//     clone(): Promise<any>
// }

// Object.prototype.clone = async function (): Promise<any>{
//     return { ...this }
// }
interface String {
  firstLetterCapitalize(): string;
}

String.prototype.firstLetterCapitalize = function (): string {
  if (this === undefined) return "";

  const ret = this as string;

  return ret.charAt(0).toUpperCase() + ret.slice(1);
};
