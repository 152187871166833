// import { ConduxService } from "./condux.service";
import { ConduxApiParticipantV1ObjectDetectionPostRequest, ConduxApiParticipantV1ObjectDetectionPostResponse } from "@conduxio/types";
import { ConduxParticipantService } from "./conduxParticipant.service";

export class ParticipantAIService extends ConduxParticipantService {
  protected modelNameSingular = "ai";
  protected modelNamePlural = "ai";

  public objectDetect(request: ConduxApiParticipantV1ObjectDetectionPostRequest): Promise<ConduxApiParticipantV1ObjectDetectionPostResponse> {
    const apiRequest = { url: "object-detection", object: request };

    return super.post<ConduxApiParticipantV1ObjectDetectionPostResponse>(apiRequest);
  }
}
