import { DateTimeHelper } from "@conduxio/types";

export class RelativeTimeHelper {
  private static EPOCHS: { name: string; seconds: number }[] = [
    { name: "year", seconds: 31536000 },
    { name: "month", seconds: 2592000 },
    { name: "day", seconds: 86400 },
    { name: "hour", seconds: 3600 },
    { name: "minute", seconds: 60 },
    { name: "Just now", seconds: 1 },
  ];

  public static getRelativeTime(dateTimeOrTimestamp: string | number, timezone?: string): string {
    let timestamp: number;

    if (typeof dateTimeOrTimestamp === "string" && DateTimeHelper.validateDateTime(dateTimeOrTimestamp).valid) {
      if (timezone === void 0 || timezone === null || timezone === "") {
        // Get the current timezone
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
      timestamp = DateTimeHelper.getTimestamp(dateTimeOrTimestamp as string, timezone) as number;
    } else if (typeof dateTimeOrTimestamp === "number" && dateTimeOrTimestamp !== 0) {
      timestamp = dateTimeOrTimestamp as number;
    } else {
      return "-";
    }

    const timeDiffInSeconds = Math.floor((Date.now() - timestamp) / 1000);
    const { interval, epochName } = this.getDuration(Math.abs(timeDiffInSeconds));

    if (epochName === "Just now") {
      return epochName;
    } else {
      const suffix = interval === 1 ? "" : "s";
      const prefix = epochName === "month" ? "about " : epochName === "year" ? "about " : "";

      if (timeDiffInSeconds >= 0) {
        // past
        return `${prefix} ${interval} ${epochName}${suffix} ago`.trim().replace("  ", " ");
      } else {
        // future
        return `In ${prefix} ${interval} ${epochName}${suffix}`.trim().replace("  ", " ");
      }
    }
  }

  private static getDuration(timeDiffInSeconds: number): { interval: number; epochName: string } {
    for (const epoch of this.EPOCHS) {
      const interval = Math.floor(timeDiffInSeconds / epoch.seconds);

      if (interval >= 1) {
        return {
          interval,
          epochName: epoch.name,
        };
      }
    }

    // Default
    return {
      interval: 0,
      epochName: "Just now",
    };
  }
}
