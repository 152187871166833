<template>
  <div class="text-center">
    This section is coming soon! The {{ componentName }} component is currently under development and will be available shortly.<br />Stay tuned!
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ComingSoonForFeature",
  props: {
    componentName: {
      type: String,
      required: false,
      default: "component",
    },
  },
});
</script>
