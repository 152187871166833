/*
  ./store/dialogs.ts
  dialogs store
**/
import { defineStore } from "pinia";

export type RootState = {
  privacy: boolean;
  tos: boolean;
  cookie: boolean;
};

export const useDialogsStore = defineStore({
  id: "dialog-store",
  state: () =>
    ({
      privacy: false,
      tos: false,
      cookie: false,
    }) as RootState,

  // actions
  actions: {
    showPrivacy() {
      this.privacy = true;
    },
    closePrivacy() {
      this.privacy = false;
    },

    showTermsAndConditions() {
      this.tos = true;
    },
    closeTermsAndConditions() {
      this.tos = false;
    },

    showCookiePolicy() {
      this.cookie = true;
    },
    closeCookiePolicy() {
      this.cookie = false;
    },
  },
});
