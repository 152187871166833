import { ConduxService } from "@/services/condux.service";
import {
  ConduxApiCommonV1Response,
  ConduxApiResearcherV1StudiesGetRequest,
  ConduxApiResearcherV1StudiesGetResponse,
  ConduxApiResearcherV1StudyGetResponse,
  ConduxApiResearcherV1StudyPostResponse,
  ConduxApiResearcherV1StudyPutResponse,
  Study,
  StudyHelper,
} from "@conduxio/types";

export class StudyService extends ConduxService {
  protected modelNameSingular = "study";
  protected modelNamePlural = "studies";

  public read(id: string, withoutWorkspaceId = false, workspaceId = "", include = ""): Promise<ConduxApiResearcherV1StudyGetResponse> {
    const apiRequest = { url: this.modelNameSingular + "/" + id, withoutWorkspaceId: withoutWorkspaceId, workspaceId: workspaceId, include: include };

    const ret = super.get<ConduxApiResearcherV1StudyGetResponse>(apiRequest);
    ret.then((res) => {
      this.studyParse(res.study);
    });
    return ret;
  }

  public readAll(
    request: ConduxApiResearcherV1StudiesGetRequest,
    withoutWorkspaceId = false,
    workspaceId = "",
    include = ""
  ): Promise<ConduxApiResearcherV1StudiesGetResponse> {
    const apiRequest = {
      url: this.modelNameSingular,
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      include: include,
      request: request,
    };

    const ret = super.get<ConduxApiResearcherV1StudiesGetResponse>(apiRequest);

    ret.then((res) => {
      for (const study of res.studies) {
        this.studyParse(study);
      }
    });
    return ret;
  }

  public create(model: Study, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiResearcherV1StudyPostResponse> {
    const validationResult = StudyHelper.validate(model, false, true);

    if (!validationResult.valid) {
      return new Promise<ConduxApiResearcherV1StudyPostResponse>((resolve) => {
        resolve({
          status: "ERROR",
          errorMessage: "Error creating Study, " + validationResult.invalidReason,
        } as ConduxApiResearcherV1StudyPostResponse);
      });
    }

    const apiRequest = {
      url: this.modelNameSingular,
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      object: { study: validationResult.study },
    };

    return super.post<ConduxApiResearcherV1StudyPostResponse>(apiRequest);
  }

  public update(model: Study, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiResearcherV1StudyPutResponse> {
    const validationResult = StudyHelper.validate(model);

    if (!validationResult.valid) {
      return new Promise<ConduxApiResearcherV1StudyPutResponse>((resolve) => {
        resolve({
          status: "ERROR",
          errorMessage: "Error updating Study, " + validationResult.invalidReason,
        } as ConduxApiResearcherV1StudyPutResponse);
      });
    }

    const apiRequest = {
      url: this.modelNameSingular + "/" + model.id,
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      object: { study: validationResult.study },
      modelId: model.id,
    };

    return super.put<ConduxApiResearcherV1StudyPutResponse>(apiRequest);
  }

  public remove(id: string, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiCommonV1Response> {
    const apiRequest = { url: this.modelNameSingular + "/" + id, withoutWorkspaceId: withoutWorkspaceId, workspaceId: workspaceId, modelId: id };

    return super.delete<ConduxApiCommonV1Response>(apiRequest);
  }

  private studyParse(study: Study): void {
    if (study.startDate) study.startDate = new Date(study.startDate);
    if (study.endDate) study.endDate = new Date(study.endDate);
  }
}
