import { ConduxService } from "@/services/condux.service";
import {
  ConduxApiParticipantAppV1LocateParticipantsPostRequest,
  ConduxApiParticipantAppV1LocateParticipantsPostResponse,
  ConduxApiParticipantAppV1LocateParticipantsInvitePostRequest,
  ConduxApiCommonV1Response,
} from "@conduxio/types";

export class LocalizationService extends ConduxService {
  protected modelNameSingular = "localization";
  protected modelNamePlural = "localizations";

  public postArea(
    request: ConduxApiParticipantAppV1LocateParticipantsPostRequest,
    withoutWorkspaceId = false,
    workspaceId = ""
  ): Promise<ConduxApiParticipantAppV1LocateParticipantsPostResponse> {
    const apiRequest = {
      url: "locate-participants",
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      object: request,
      modelName: "localization area",
    };

    return super.post<ConduxApiParticipantAppV1LocateParticipantsPostResponse>(apiRequest);
  }

  public inviteParticipants(
    request: ConduxApiParticipantAppV1LocateParticipantsInvitePostRequest,
    withoutWorkspaceId = false,
    workspaceId = ""
  ): Promise<ConduxApiCommonV1Response> {
    const apiRequest = {
      url: "locate-participants/invite",
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      object: request,
      modelName: "invite for participant",
    };

    return super.post<ConduxApiCommonV1Response>(apiRequest);
  }
}
