<template>
  <div>
    <div class="bar">
      <v-progress-linear v-if="$globalsService.showLoading" color="primary" indeterminate />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoaderBase",
});
</script>
<style scoped>
.bar {
  position: fixed;
  width: 100%;
  left: 0;
  top: 60px;
  z-index: 10000;
}
</style>
