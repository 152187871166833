import { ConduxService } from "@/services/condux.service";
import { ConduxApiResearcherV1SessionPlaybackGetResponse } from "@conduxio/types";

export class SessionPlaybackService extends ConduxService {
  protected modelNameSingular = "session-playback";
  protected modelNamePlural = "session-playbacks";

  public read(id: string, include = ""): Promise<ConduxApiResearcherV1SessionPlaybackGetResponse> {
    const apiRequest = { url: this.modelNameSingular + "/" + id, withoutWorkspaceId: false, workspaceId: null, include: include };

    return super.get<ConduxApiResearcherV1SessionPlaybackGetResponse>(apiRequest);
  }
}
