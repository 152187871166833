<template>
  <v-row class="mt-4 pt-4">
    <v-col cols="12" class="pt-0 text-center">
      We’re working hard to bring this feature to life!<br />
      🚀 Stay tuned—something amazing is on its way.<br />
      {{ message }}
    </v-col>
    <v-col cols="12" class="pt-0 text-center">
      <img src="@/assets/images/coming_soon.png" alt="Coming Soon" style="width: 200px" />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ComingSoonForPage",
  props: {
    message: {
      type: String,
      required: false,
      default: "",
    },
  },
});
</script>
