import { ConduxService } from "@/services/condux.service";
import {
  ConduxApiCommonV1Response,
  ConduxApiResearcherV1WorkspaceGetResponse,
  ConduxApiResearcherV1WorkspacePutResponse,
  ConduxApiResearcherV1WorkspacesGetRequest,
  ConduxApiResearcherV1WorkspacesGetResponse,
  Workspace,
  WorkspaceHelper,
} from "@conduxio/types";

export class WorkspaceService extends ConduxService {
  protected modelNameSingular = "workspace";
  protected modelNamePlural = "workspaces";

  public read(id: string, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiResearcherV1WorkspaceGetResponse> {
    const apiRequest = { url: this.modelNameSingular + "/" + id, withoutWorkspaceId: withoutWorkspaceId, workspaceId: workspaceId };

    return super.get<ConduxApiResearcherV1WorkspaceGetResponse>(apiRequest);
  }

  public readAll(
    request: ConduxApiResearcherV1WorkspacesGetRequest,
    withoutWorkspaceId = false,
    workspaceId = ""
  ): Promise<ConduxApiResearcherV1WorkspacesGetResponse> {
    const apiRequest = { url: this.modelNameSingular, withoutWorkspaceId: withoutWorkspaceId, workspaceId: workspaceId, request: request };

    return super.get<ConduxApiResearcherV1WorkspacesGetResponse>(apiRequest);
  }

  public create(): Promise<ConduxApiCommonV1Response> {
    // eslint-disable-line no-unused-vars
    return new Promise<ConduxApiCommonV1Response>((resolve) => {
      resolve({
        status: "ERROR",
        errorMessage: "Error creating Workspace, Not Supported",
      } as ConduxApiCommonV1Response);
    });
  }

  public update(model: Workspace, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiResearcherV1WorkspacePutResponse> {
    const validationResult = WorkspaceHelper.validate(model);

    if (!validationResult.valid) {
      return new Promise<ConduxApiResearcherV1WorkspacePutResponse>((resolve) => {
        resolve({
          status: "ERROR",
          errorMessage: "Error updating Workspace, " + validationResult.invalidReason,
        } as ConduxApiResearcherV1WorkspacePutResponse);
      });
    }

    const apiRequest = {
      url: this.modelNameSingular + "/" + model.id,
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      object: { workspace: validationResult.workspace },
      modelId: model.id,
    };

    return super.put<ConduxApiResearcherV1WorkspacePutResponse>(apiRequest);
  }

  public remove(id: string, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiCommonV1Response> {
    const apiRequest = { url: this.modelNameSingular + "/" + id, withoutWorkspaceId: withoutWorkspaceId, workspaceId: workspaceId, modelId: id };

    return super.delete<ConduxApiCommonV1Response>(apiRequest);
  }

  public clear(): Promise<ConduxApiCommonV1Response> {
    const apiRequest = { url: this.modelNameSingular + "/test/cleanup" };

    return super.get<ConduxApiCommonV1Response>(apiRequest);
  }
}
