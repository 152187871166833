import { ConduxService } from "@/services/condux.service";
import {
  ConduxApiResearcherV1ParticipantSearchCriteriasGetResponse,
  ConduxApiResearcherV1ParticipantSearchPostRequest,
  ConduxApiResearcherV1ParticipantSearchPostResponse,
  ConduxApiResearcherV1ParticipantSearchInvitePostRequest,
  ConduxApiCommonV1Response,
} from "@conduxio/types";

export class FMPService extends ConduxService {
  protected modelNameSingular = "FMP";
  protected modelNamePlural = "FMP";

  public async getSearchCriteria(withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiResearcherV1ParticipantSearchCriteriasGetResponse> {
    const apiRequest = {
      url: "participant/search-criteria",
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      modelName: "FMP search criteria",
    };

    return super.get<ConduxApiResearcherV1ParticipantSearchCriteriasGetResponse>(apiRequest);
  }

  public search(
    request: ConduxApiResearcherV1ParticipantSearchPostRequest,
    withoutWorkspaceId = false,
    workspaceId = ""
  ): Promise<ConduxApiResearcherV1ParticipantSearchPostResponse> {
    const apiRequest = {
      url: "participant/search",
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      object: request,
      modelName: "FMP search",
    };

    return super.post<ConduxApiResearcherV1ParticipantSearchPostResponse>(apiRequest);
  }

  public invite(
    request: ConduxApiResearcherV1ParticipantSearchInvitePostRequest,
    withoutWorkspaceId = false,
    workspaceId = ""
  ): Promise<ConduxApiCommonV1Response> {
    const apiRequest = {
      url: `participant/search/${request.id}/invite`,
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      object: request,
      modelName: "FMP invite",
    };

    return super.post<ConduxApiCommonV1Response>(apiRequest);
  }
}
