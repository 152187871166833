<template>
  <div class="participant">
    <div>
      <v-container class="pb-12">
        <slot></slot>
      </v-container>
    </div>
    <FooterBar />
  </div>
</template>

<script lang="ts">
//
import { defineComponent } from "vue";

// components
import FooterBar from "@/layouts/Components/FooterBar.vue";

import { useMessageBoxStore } from "../store/message-box";
import "@/participant/assets/scss/participant.scss";

export default defineComponent({
  name: "ParticipantLayout",
  components: {
    FooterBar,
  },
  setup() {
    const messageBox = useMessageBoxStore(); // declare the mb's store

    window.stores = { messageBox }; // initialize the mb store

    return {
      messageBox,
    };
  },

  // #region Properties
  data() {
    return {
      drawer: false,
    };
  },
  computed: {},
  watch: {},
  // #endregion Properties
  methods: {},
});
</script>

<style lang="scss">
.participant {
  .v-container {
    max-width: 1200 !important;
  }
}
</style>
