<template>
  <div v-if="isLoading" style="text-align: center" class="my-3">
    <v-progress-circular indeterminate color="primary" size="small" /><span class="ml-2 d-inline">{{ msg }}</span>
  </div>
  <template v-else>
    <slot></slot>
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "ContentBase",
  props: {
    msg: {
      type: String,
    },
    isLoading: {
      type: Boolean,
    },
  },
  setup() {
    return {};
  },
  computed: {},
});
</script>
