/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import { loadFonts } from "./webfontloader";
import vuetify from "./vuetify";
import pinia from "../store";
import router from "../router";
import { registerSentry } from "./sentry";
// Types
import type { App } from "vue";
import HighchartsVue from "highcharts-vue";

export function registerPlugins(app: App): void {
  registerSentry(app);
  loadFonts();
  app
    .use(vuetify)
    .use(router)
    .use(pinia)
    .use<any>(HighchartsVue as any);
}
