import { useNotification } from "@kyvg/vue3-notification";

export class Notification {
  public notification = useNotification();

  success(text: string, title = "Success", duration: 10000): void {
    this.sendNotification(title, text, "success", duration);
  }
  warning(text: string, title = "Warning", duration: 10000): void {
    this.sendNotification(title, text, "warn", duration);
  }
  error(text: string, title = "Error", duration: 10000): void {
    this.sendNotification(title, text, "error", duration);
  }
  info(text: string, title = "Info", duration: 10000): void {
    this.sendNotification(title, text, "info", duration);
  }
  sendNotification(title: string, text: string, type: string, duration: number): void {
    const id = Date.now();

    this.notification.notify({
      id,
      duration: duration,
      title: title,
      text: text,
      type: type,
    });
  }
}
