import { ConduxService } from "@/services/condux.service";
import {
  ConduxApiCommonV1Response,
  ConduxApiResearcherV1OauthAccountGetResponse,
  ConduxApiResearcherV1OauthAccountPostResponse,
  ConduxApiResearcherV1OauthAccountsGetRequest,
  ConduxApiResearcherV1OauthAccountsGetResponse,
  OauthAccount,
} from "@conduxio/types";

export class OauthAccountService extends ConduxService {
  protected modelNameSingular = "oauth-account";
  protected modelNamePlural = "oauth-accounts";

  // New API: all the oauth-account crud API DO require workspace_id
  public read(
    id: string,
    withoutWorkspaceId = false,
    workspaceId = this.globalsService.getWorkspaceId()
  ): Promise<ConduxApiResearcherV1OauthAccountGetResponse> {
    const apiRequest = { url: this.modelNameSingular + "/" + id, withoutWorkspaceId: withoutWorkspaceId, workspaceId: workspaceId };
    return super.get<ConduxApiResearcherV1OauthAccountGetResponse>(apiRequest);
  }
  // New API: all the oauth-account crud API DO require workspace_id
  public readAll(
    request: ConduxApiResearcherV1OauthAccountsGetRequest,
    withoutWorkspaceId = false,
    workspaceId = this.globalsService.getWorkspaceId()
  ): Promise<ConduxApiResearcherV1OauthAccountsGetResponse> {
    const apiRequest = { url: this.modelNameSingular, withoutWorkspaceId: withoutWorkspaceId, workspaceId: workspaceId, request: request };
    return super.get<ConduxApiResearcherV1OauthAccountsGetResponse>(apiRequest);
  }
  // New API: all the oauth-account crud API DO require workspace_id
  public create(
    model: OauthAccount,
    withoutWorkspaceId = false,
    workspaceId = this.globalsService.getWorkspaceId()
  ): Promise<ConduxApiResearcherV1OauthAccountPostResponse> {
    // TODO: New API
    // Until we'll change the ConduxApiResearcherV1OauthAccountPostRequest
    // according to tasks https://app.clickup.com/t/86bwukavf and https://app.clickup.com/t/86bwukkvu
    /*     const validationResult = OauthAccountHelper.validate(model, false)    // here we DON'T need to validate the id
    if (!validationResult.valid) {
      return new Promise<ConduxApiResearcherV1OauthAccountPostResponse>((resolve) => {
        resolve({
          status: 'ERROR',
          errorMessage: 'Error creating OauthAccount, ' + validationResult.invalidReason,
        } as ConduxApiResearcherV1OauthAccountPostResponse)
      })
    } */

    // TODO: New API
    // Until we'll change the ConduxApiResearcherV1OauthAccountPostRequest
    // according to tasks https://app.clickup.com/t/86bwukavf and https://app.clickup.com/t/86bwukkvu
    const apiRequest = {
      url: this.modelNameSingular,
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      object: { oauthAccount: { type: model.type, code: model.code } },
    };
    return super.post<ConduxApiResearcherV1OauthAccountPostResponse>(apiRequest);
  }
  // New API: PUT doesn't really exist in the new API. We use POST/create for both cases: creating a new account account + updating an existing account
  // While we're updating, the key is the researcher_id (from the API token) and the account_type
  // Therefore, the update actually performs a create
  public update(
    model: OauthAccount,
    withoutWorkspaceId = false,
    workspaceId = this.globalsService.getWorkspaceId()
  ): Promise<ConduxApiResearcherV1OauthAccountPostResponse> {
    // TODO: New API
    // Until we'll change the ConduxApiResearcherV1OauthAccountPostRequest
    // according to tasks https://app.clickup.com/t/86bwukavf and https://app.clickup.com/t/86bwukkvu
    /*     const validationResult = OauthAccountHelper.validate(model, true)     // here we need to validate also the id

    if (!validationResult.valid) {
      return new Promise<ConduxApiResearcherV1OauthAccountPostResponse>((resolve) => {
        resolve({
          status: 'ERROR',
          errorMessage: 'Error updating OauthAccount, ' + validationResult.invalidReason,
        } as ConduxApiResearcherV1OauthAccountPostResponse)
      })
    } */

    // TODO: New API
    // Until we'll change the ConduxApiResearcherV1OauthAccountPostRequest
    // according to tasks https://app.clickup.com/t/86bwukavf and https://app.clickup.com/t/86bwukkvu
    const apiRequest = {
      url: this.modelNameSingular,
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      object: { oauthAccount: { type: model.type, code: model.code } },
    };
    return super.post<ConduxApiResearcherV1OauthAccountPostResponse>(apiRequest);
  }
  // New API: all the oauth-account crud API DO require workspace_id
  public remove(id: string, withoutWorkspaceId = false, workspaceId = this.globalsService.getWorkspaceId()): Promise<ConduxApiCommonV1Response> {
    const apiRequest = { url: this.modelNameSingular + "/" + id, withoutWorkspaceId: withoutWorkspaceId, workspaceId: workspaceId, modelId: id };
    return super.delete<ConduxApiCommonV1Response>(apiRequest);
  }
}
