import { ConduxService } from "@/services/condux.service";
import {
  ConduxApiResearcherV1TimeIntervalsCommonRequest,
  ConduxApiResearcherV1DashboardChartsGetResponse,
  ConduxApiResearcherV1LimitCommonRequest,
  ConduxApiResearcherV1DashboardStudiesGetResponse,
  ConduxApiResearcherV1DashboardSessionsGetResponse,
  DashboardStudy,
} from "@conduxio/types";
export class DashboardService extends ConduxService {
  protected modelNameSingular = "dashboard";
  protected modelNamePlural = "dashboard";

  public async getChartsData(
    request: ConduxApiResearcherV1TimeIntervalsCommonRequest,
    withoutWorkspaceId = false,
    workspaceId = ""
  ): Promise<ConduxApiResearcherV1DashboardChartsGetResponse> {
    const apiRequest = {
      url: "dashboard/charts",
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      request: request,
      modelName: "dashboard charts",
    };

    return super.get<ConduxApiResearcherV1DashboardChartsGetResponse>(apiRequest);
  }
  public getStudiesData(
    request: ConduxApiResearcherV1LimitCommonRequest,
    withoutWorkspaceId = false,
    workspaceId = ""
  ): Promise<ConduxApiResearcherV1DashboardStudiesGetResponse> {
    const apiRequest = {
      url: "dashboard/studies",
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      request: request,
      modelName: "dashboard studies",
    };

    const ret = super.get<ConduxApiResearcherV1DashboardStudiesGetResponse>(apiRequest);

    ret.then((res) => {
      for (const study of res.studies) {
        this.studyParse(study);
      }
    });
    return ret;
  }
  public getSessionsData(
    request: ConduxApiResearcherV1TimeIntervalsCommonRequest,
    withoutWorkspaceId = false,
    workspaceId = ""
  ): Promise<ConduxApiResearcherV1DashboardSessionsGetResponse> {
    const apiRequest = {
      url: "dashboard/sessions",
      withoutWorkspaceId: withoutWorkspaceId,
      workspaceId: workspaceId,
      request: request,
      modelName: "dashboard sessions",
    };

    return super.get<ConduxApiResearcherV1DashboardSessionsGetResponse>(apiRequest);
  }

  private studyParse(study: DashboardStudy): void {
    study.startDate = new Date(study.startDate);
    if (study.endDate) study.endDate = new Date(study.endDate);
  }
}
