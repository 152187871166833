<template>
  <v-navigation-drawer
    v-if="showNavControls"
    v-model="drawer"
    class="b-green c-light_gray left-menu"
    :permanent="$vuetify.display.mdAndUp"
    :width="240"
  >
    <v-list>
      <v-list-item to="/home">
        <img src="@/assets/images/logo/logo.png" class="logo" />
        <span class="release">{{ release }}</span>
      </v-list-item>
      <v-list-item class="d-flex">
        <WorkspaceSelect />
      </v-list-item>
    </v-list>
    <v-divider class="my-2" />
    <!-- main menu -->
    <v-list nav class="c-light_gray">
      <template v-for="item in menu.filter((m) => m.visible && !m.bottom)" :key="item">
        <v-list-item
          v-if="!item.children && item.visible"
          :prepend-icon="item.icon"
          :title="item.title"
          link
          :to="item.url"
          active-class="b-mint c-green"
        />
        <!-- father items -->
        <v-list-group v-if="item.children && item.visible">
          <template #activator="{ props }">
            <v-list-item v-bind="props" :title="item.title" :prepend-icon="item.icon" />
          </template>
          <!-- child items -->
          <template v-for="child in item.children" :key="child">
            <v-list-item v-if="child.visible" :title="child.title" :to="child.url" active-class="b-mint c-green" />
          </template>
        </v-list-group>
      </template>
    </v-list>
    <template #append>
      <v-list nav>
        <v-list-item
          v-for="item in menu.filter((m) => m.visible && m.bottom)"
          :key="item.title"
          :prepend-icon="item.icon"
          :title="item.title"
          link
          :to="item.url"
          active-class="b-mint c-green"
          class="mb-4"
        />
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
//imports menu items
import menu from "../../static/menu/main-menu";
import WorkspaceSelect from "@/layouts/Components/WorkspaceSelect.vue";

export default defineComponent({
  name: "MainMenu",
  components: {
    WorkspaceSelect,
  },
  props: {
    modelValue: {
      type: Boolean,
    },
  },
  emits: ["update:modelValue"],
  setup() {
    return { menu };
  },
  // #region Properties
  data() {
    return {
      drawer: this.modelValue,
    };
  },
  computed: {
    // Returns release name from env
    release(): string {
      return `${import.meta.env.VITE_APP_RELEASE}`;
    },
    showNavControls(): boolean {
      /*
        Date    2023-06-08
        Author  Simone
        Task    "Navigation controls show during a login or registration" https://app.clickup.com/t/863gyezw6
      **/
      // Returns false if, for some reason, we have to hide navigation controls on the current page
      // This is needed because:
      //  during a login a registration or something like that, the left-bar and the other controls
      //  in the top-bar used to be shown after the API call before the user redirection though.
      //  This makes sure these controls are hidden until a complete redirect.
      switch (this.$route.name) {
        case "RegisterHubspot":
        case "LoginForm":
        case "ParticipantSurvey":
          return false;
        default:
          return true;
      }
    },
  },
  // #endregion Properties

  watch: {
    drawer: function (newVal) {
      this.$emit("update:modelValue", newVal);
    },
    modelValue: function (newVal) {
      this.drawer = newVal;
    },
  },
  // #endregion Properties
});
</script>

<style lang="scss">
.left-menu {
  z-index: 1101 !important;
  .v-list-item {
    color: white;
  }
}

img.logo {
  width: 200px;
}

span.release {
  position: absolute;
  right: 8px;
  bottom: 0;
  font-size: 0.6em;
  opacity: 0.3;
  text-transform: uppercase;
  font-weight: bold;
}

img.logo {
  width: 200px;
}

span.release {
  position: absolute;
  right: 8px;
  bottom: 0;
  font-size: 0.6em;
  opacity: 0.3;
  text-transform: uppercase;
  font-weight: bold;
}
</style>
