<!--
  message-box.vue
-->
<template>
  <v-dialog
    v-model="config.display"
    data-cy="dialog"
    :max-width="$vuetify.display.xs ? `100%` : config.message.large ? 800 : 500"
    :persistent="!config.message.canCancel"
    class="message-box dialog"
  >
    <v-card width="100%">
      <!-- icon + title + cancel button -->
      <v-card-title :class="config.message.errorMessage === '' ? `bg-primary text--lighten-5` : `bg-error`">
        <div class="align-self-center">
          <v-icon v-if="config.message.icon !== ''" size="small" :dark="config.message.errorMessage !== ''" class="mr-2">
            {{ config.message.icon }}
          </v-icon>
          {{ config.message.title }}
        </div>
        <v-spacer />

        <v-btn v-if="config.message.canCancel" variant="text" :icon="$icons.close" @click.stop="cancel()" />
      </v-card-title>

      <!-- body -->
      <v-card-text class="body mt-4 mb-2">
        <span v-html="config.message.message"></span>
        <div v-if="config.message.subMessage" class="text-caption mt-3">
          <span v-html="config.message.subMessage"></span>
        </div>

        <!-- input section -->
        <v-text-field
          v-if="config.message.input && (!config.message.input.type || config.message.input.type === 'text')"
          v-model="config.message.input.value"
          class="mt-3 mb-3"
          :label="config.message.input.label"
          :placeholder="config.message.input.placeholder"
          autofocus
          hide-details
        />
        <v-color-picker v-if="config.message.input && config.message.input.type === 'color'" v-model="config.message.input.value" class="w-100" />
        <!-- errorMessage -->
        <br v-if="config.message.errorMessage !== ''" />
        <br v-if="config.message.errorMessage !== ''" />
        <b>
          <span v-if="config.message.errorMessage !== ''" class="txt-error">Error Details</span>
        </b>
        <br v-if="config.message.errorMessage !== ''" />
        <span v-if="config.message.errorMessage !== ''" class="txt-error" v-html="config.message.errorMessage"></span>
      </v-card-text>

      <!-- link -->
      <v-card-text v-if="config.message.link" class="mb-1">
        <a :href="config.message.link.url" :target="config.message.link.self ? '_self' : '_blank'">
          <v-icon>mdi-link-variant</v-icon>
          {{ config.message.link.text }}</a
        >
      </v-card-text>

      <!-- details -->
      <transition name="fade">
        <v-card-text v-if="config.message.details && config.message.details.length > 0" v-show="config.message.showDetails" class="details">
          <h4>{{ detailsTitle }}</h4>
          <ul data-cy="details" class="ma-4">
            <li v-for="detail in config.message.details" :key="detail">
              <span :class="config.message.errorMessage !== '' ? 'red--text' : ''" v-html="detail"> </span>
            </li>
          </ul>
        </v-card-text>
      </transition>

      <!-- show/hide details button (when uses Don't show this message again) -->
      <v-btn
        v-if="config.message.details && config.message.details.length > 0 && config.message.dontShowAgain !== ''"
        class="mb-1"
        variant="text"
        @click="config.message.showDetails = !config.message.showDetails"
      >
        <v-icon v-if="config.message.showDetails">mdi-chevron-left</v-icon>
        {{ config.message.showDetails ? "less details" : "more details" }}
        <v-icon v-if="!config.message.showDetails">mdi-chevron-right</v-icon>
      </v-btn>

      <v-divider />

      <!-- don't show again checkbox, show/hide details, buttons -->
      <v-card-actions>
        <v-checkbox
          v-if="config.message.dontShowAgain !== ''"
          id="message_box_dontshowagain"
          v-model="dontShowAgainCheckBox"
          hide-details
          color="primary"
          label="Don't show this message again"
        />
        <v-btn
          v-if="config.message.details && config.message.details.length > 0 && config.message.dontShowAgain === ''"
          variant="text"
          @click="config.message.showDetails = !config.message.showDetails"
        >
          <v-icon v-if="config.message.showDetails">mdi-chevron-left</v-icon>
          {{ config.message.showDetails ? "less details" : "more details" }}
          <v-icon v-if="!config.message.showDetails">mdi-chevron-right</v-icon>
        </v-btn>

        <!-- buttons -->
        <template v-for="btn in config.message.buttons" :key="btn.key">
          <v-btn
            :data-cy="`button-${btn.key}`"
            :class="getButtonClass(btn)"
            :type="getButtonType(btn)"
            :variant="getButtonVariant(btn)"
            @click.stop="confirm(btn)"
          >
            <v-icon v-if="btn.icon" class="mr-1">{{ btn.icon }} </v-icon>
            {{ btn.text }}
          </v-btn>
          <v-spacer v-if="btn.key === 'cancel'" />
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useMessageBoxStore, ButtonConfig } from "../../store/message-box";

export default defineComponent({
  name: "MessageBox",
  setup() {
    const config = useMessageBoxStore(); // declare the mb's store

    window.stores = { config }; // initialize the mb store

    return {
      config,
    };
  },

  // #region Properties
  data() {
    return {
      dontShowAgainCheckBox: false,
    };
  },
  computed: {
    detailsTitle() {
      return this.config.message.detailsTitle ?? "Details";
    },
  },
  // #endregion Properties

  // #region Watchers
  watch: {},
  // #endregion Watchers

  // #region Methods
  methods: {
    confirm(button: ButtonConfig) {
      // Don't show this message again
      let { key } = button;

      // input box returns the input value, and its "cancel" button returns empty string
      if (this.config.message.input !== undefined) {
        switch (key) {
          case "cancel":
            this.cancel();

            return;
          default:
            key = String(this.config?.message?.input?.value);
        }
      }

      // don't show again
      if (this.dontShowAgainCheckBox) {
        if (this.config.message.dontShowAgain !== "") localStorage.setItem("message-box." + this.config.message.dontShowAgain, key);
      }
      this.config.callback(key);
      this.config.display = false;
    },
    cancel() {
      this.config.callback("");
      this.config.display = false;
    },

    // #region Utilities
    getButtonClass(button: ButtonConfig): string {
      if (!button.button) return "";

      let btnClass = "primary";

      if (this.config.message.errorMessage === "") {
        if (button.button) btnClass += " bg-primary"; // non text button
        btnClass += " lighten-2 primary--text text--darken-3";
      } else btnClass += " bg-error";

      return btnClass;
    },
    getButtonVariant(button: ButtonConfig): string {
      if (button.key === "cancel") return "tonal";
      return "";
    },
    getButtonType(button: ButtonConfig): string {
      // returns button type based on the button key
      switch (button.key.toLowerCase().trim()) {
        case "ok":
          return "submit";
        case "save":
          return "submit";
        case "submit":
          return "submit";
        case "cancel":
          return "reset";
        case "undo":
          return "reset";
        case "reset":
          return "reset";
        default:
          return "button";
      }
    },
    // #endregion Utilities
  },
  // #endregion Methods
});
</script>

<style scoped>
label[for="message_box_dontshowagain"] {
  font-size: 0.9em !important;
  height: 24px !important;
  line-height: 24px !important;
}

.message-box .v-overlay__content {
  width: 100%;
}

.v-card-text.details {
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 14px !important;
}

.txt-error {
  color: rgb(var(--v-theme-error)); /* text for errors */
}
</style>
