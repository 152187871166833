import { ConduxService } from "@/services/condux.service";
import { ConduxApiCommonV1Response, ConduxApiResearcherV1GeneratePlaybackDownloadPostRequest } from "@conduxio/types";

export class SessionPlaybackDownloadService extends ConduxService {
  protected modelNameSingular = "session-playback-download";
  protected modelNamePlural = "session-playback-downloads";

  public read(id: string, withoutWorkspaceId = false, workspaceId = ""): Promise<any> {
    const apiRequest = { url: this.modelNameSingular + "/" + id, withoutWorkspaceId: withoutWorkspaceId, workspaceId: workspaceId };
    return super.get<any>(apiRequest);
  }

  public readAll(withoutWorkspaceId = false, workspaceId = ""): Promise<any> {
    const apiRequest = { url: this.modelNameSingular, withoutWorkspaceId: withoutWorkspaceId, workspaceId: workspaceId };
    return super.get<any>(apiRequest);
  }
  public readAllForSession(id: string, withoutWorkspaceId = false, workspaceId = ""): Promise<any> {
    const apiRequest = { url: this.modelNameSingular + "/" + id, withoutWorkspaceId: withoutWorkspaceId, workspaceId: workspaceId };
    return super.get<any>(apiRequest);
  }
  public create(
    model: ConduxApiResearcherV1GeneratePlaybackDownloadPostRequest,
    withoutWorkspaceId = false,
    workspaceId = ""
  ): Promise<ConduxApiCommonV1Response> {
    const apiRequest = { url: this.modelNameSingular, withoutWorkspaceId: withoutWorkspaceId, workspaceId: workspaceId, object: model };
    return super.post<ConduxApiCommonV1Response>(apiRequest);
  }

  public remove(id: string, withoutWorkspaceId = false, workspaceId = ""): Promise<ConduxApiCommonV1Response> {
    const apiRequest = { url: this.modelNameSingular + "/" + id, withoutWorkspaceId: withoutWorkspaceId, workspaceId: workspaceId, modelId: id };
    return super.delete<ConduxApiCommonV1Response>(apiRequest);
  }
}
