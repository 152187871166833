/* eslint-disable */
interface String {
  typeSupportRules(): boolean;
  isTypeQuestionCode(): boolean;
  isTypeSingleSelect(): boolean;
  textFieldType(): string;
  isTypeImage(): boolean;
}

String.prototype.typeSupportRules = function (): boolean {
  const supportedTypesForVisibilityCondition = [
    "radio",
    "radio_image",
    "checkbox",
    "checkbox_image",
    "select",
    "rating",
    "date",
    "number",
    "obj_detect",
  ];

  return supportedTypesForVisibilityCondition.includes(this.toString());
};
String.prototype.isTypeQuestionCode = function (): boolean {
  const codeTypes = ["radio", "radio_image", "checkbox", "checkbox_image", "select", "obj_detect"];

  return codeTypes.includes(this.toString());
};
String.prototype.isTypeSingleSelect = function (): boolean {
  const codeTypes = ["radio", "radio_image", "select"];

  return codeTypes.includes(this.toString());
};
String.prototype.textFieldType = function (): string {
  if (this.toString() === "date") {
    return "date";
  } else if (this.toString() === "number" || this.toString() === "rating") {
    return "number";
  } else {
    return "";
  }
};
String.prototype.isTypeImage = function (): boolean {
  const supportedTypesForVisibilityCondition = ["radio_image", "checkbox_image"];

  return supportedTypesForVisibilityCondition.includes(this.toString());
};
