export class TokenHelper {
  private static decodeJWTToken(idToken: string | null): { exp?: number } {
    try {
      if (idToken) {
        const base64Url = idToken.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
            .join("")
        );

        return JSON.parse(jsonPayload);
      } else {
        return {};
      }
    } catch (exception) {
      return {};
    }
  }

  private static getTokenExpiryTimestamp(idToken: string | null, serverDateDifference = 0): number {
    const idTokenObject = TokenHelper.decodeJWTToken(idToken);

    if (idTokenObject && idTokenObject.exp) {
      return Number(idTokenObject.exp * 1000) + serverDateDifference;
    } else {
      return 0;
    }
  }

  public static isIdTokenValid(idToken: string | null, serverDateDifference = 0): boolean {
    const expireTimestamp = this.getTokenExpiryTimestamp(idToken, serverDateDifference);
    return expireTimestamp > 0 && Date.now() < expireTimestamp;
  }
}
